import {Injectable} from '@angular/core';
import {FeatureModel} from "../models/feature-model";
import {GeometryModel} from "../models/geometry-model";
import * as turf from "@turf/turf";
import {GeometryTypeEnum} from "../enums/geometry-type-enum.enum";
import {FeatureTypesEnum} from "../enums/feature-types-enum.enum";

@Injectable({
  providedIn: 'root'
})
export class GeometryHelper {

  constructor() {}

  static getDefaultPoint(): FeatureModel {

    let value: FeatureModel = new FeatureModel();
    value.geometry = new GeometryModel();
    value.geometry.type = GeometryTypeEnum.POINT;
    value.geometry.coordinates = [2.3707129698604206, 48.85722798031489]; // Paris

    return value;
  }

  static createPoint(coordinates: []): GeometryModel {
    let result: GeometryModel = new GeometryModel();
    result.type = GeometryTypeEnum.POINT;
    result.coordinates = coordinates;

    return result;
  }

  static createFeature(geometry: GeometryModel, properties: any = {}): FeatureModel {

    let value: FeatureModel = new FeatureModel();
    value.geometry = geometry;
    value.properties = properties;

    return value;
  }

  static isValidFeature(value: FeatureModel): boolean {
    return (value !== undefined
          && value.type === FeatureTypesEnum.FEATURE
          && GeometryHelper.isValidGeometry(value.geometry))
  }

  static isValidGeometry(value: GeometryModel): boolean {
    return (value !== undefined
      && value.type !== undefined
      && value.type !== ''
      && value.coordinates !== undefined
      && value.coordinates.length > 0)
  }

  static isValidPoint(value: GeometryModel): boolean {
    return (value !== undefined
      && value.type !== undefined
      && value.type == GeometryTypeEnum.POINT
      && value.coordinates !== undefined
      && value.coordinates.length > 0)
  }

  static getCentroid(geometry: GeometryModel): GeometryModel {
    let result: GeometryModel;

    if (geometry !== undefined) {
      let value = turf.centroid(geometry.toTurf());

      result = new GeometryModel();
      result.type = value.geometry.type;
      result.coordinates = value.geometry.coordinates;
    }

    return result;
  }
}
