import {Component, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import {AppService} from './app.service';
import * as CityViewer from 'ui-cityviewer-lib';
import {MessageService} from 'primeng/api';
import {UrlHelper} from "./helpers/url-helper";
import {GeometryModel} from "./models/geometry-model";
import {GeometryHelper} from "./helpers/geometry.helper";
import {CapturePointResponseModel} from "./models/capture-point-response-model";
import {ElementModel} from "./models/element-model";
import {ViewModel} from "./models/view-model";
import {CaptureModel} from "./models/capture-model";
import {ViewerService} from "./services/viewer.service";

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: [MessageService]
})
export class AppComponent implements OnInit {

    private long: number;
    private lat: number;
    private mode: string;
    private distance: number;
    private token: string;

    private container: any;
    private panoPlayer: any;
    private imageUrl: string;
    private viewIdentifier: string;

    private capture: CaptureModel;
    private views: ViewModel[] = [];
    private elements: ElementModel[] = [];
    private storageUrl: String;
    private location: GeometryModel;
    private showBboxTool: string;

    public isViewerLarge: boolean = false;

    constructor(
        private appService: AppService, private messageService: MessageService, private viewerService: ViewerService
    ) {
        // Default constructor
    }

    ngOnInit() {

        let url: string = window.location.href;

        this.long = UrlHelper.getUrlParam(url, 'long', undefined);
        this.lat = UrlHelper.getUrlParam(url, 'lat', undefined);
        this.mode = UrlHelper.getUrlParam(url, 'mode', 'PANO_360_M');
        this.token = UrlHelper.getUrlParam(url, 'token', undefined);
        this.distance = UrlHelper.getUrlParam(url, 'distance', 15);
        this.viewIdentifier = UrlHelper.getUrlParam(url, 'viewIdentifier', undefined);
        this.showBboxTool = UrlHelper.getUrlParam(url, 'showBboxTool', undefined);

        this.container = document.getElementById('container');

        let panoPlayerOptions = {
            'showNavigation': UrlHelper.getUrlParamTools(url, 'showNavigation', false),
            'showMeasureTool': UrlHelper.getUrlParamTools(url, 'showMeasureTool', false),
            'showViewMetadata': UrlHelper.getUrlParamTools(url, 'showViewMetadata', false),
            'showAltitude': UrlHelper.getUrlParamTools(url, 'showAltitude', false),
            'showCloseMe': UrlHelper.getUrlParamTools(url, 'showCloseMe', true),
            'showCompass': UrlHelper.getUrlParamTools(url, 'showCompass', false),
            'showBbox': UrlHelper.getUrlParamTools(url, 'showBbox', false),
            'showBboxTool': UrlHelper.getUrlParamTools(url, 'showBboxTool', false),
            'showSettings': UrlHelper.getUrlParamTools(url, 'showSettings', false),
            'showResolutionImage': UrlHelper.getUrlParamTools(url, 'showResolutionImage', false),
        };
        this.panoPlayer = new CityViewer(this.container, panoPlayerOptions);

        this.container.addEventListener('closeMe', AppComponent._sendCloseMe.bind(this), false);
        this.container.addEventListener('loadingView', AppComponent._sendLoadView.bind(this), false);
        this.container.addEventListener('measurement', AppComponent._sendMeasurement.bind(this), false);
        this.container.addEventListener('bboxCreated', this._sendBboxCreated.bind(this), false);

        this.container.addEventListener('groundNext', this._linearNextAction.bind(this), false);
        this.container.addEventListener('groundPrevious', this._linearPreviousAction.bind(this), false);

        this.container.addEventListener('panoFront', this._panoFrontAction.bind(this), false);
        this.container.addEventListener('panoLeft', this._panoLeftAction.bind(this), false);
        this.container.addEventListener('panoRight', this._panoRightAction.bind(this), false);
        this.container.addEventListener('panoBack', this._panoBackAction.bind(this), false);
        this.container.addEventListener('panoLinearNext', this._linearNextAction.bind(this), false);
        this.container.addEventListener('panoLinearPrevious', this._linearPreviousAction.bind(this), false);

        this.container.addEventListener('imageFormatTypeChange', this._imageFormatTypeChangeAction.bind(this), false);

        this.container.addEventListener('fullscreen', this._toggleViewerSize.bind(this), false);

        if (this.token != undefined) {
            if (this.viewIdentifier) {
                this.loadCaptureView(this.viewIdentifier)
            } else if (this.long != undefined && this.lat != undefined) {
                let geometry = new GeometryModel();
                geometry["type"] = "Point";
                geometry["coordinates"] = [this.long, this.lat];

                this.loadCaptureGeographic(geometry, 20);
            } else {
                console.log('Location is missing');
            }
        } else {
            console.log('Token is missing');
        }
    }


    // Viewer
    _toggleViewerSize() {
        // if (this.div.nativeElement._width< 510){
        //   //it's the oposite because first it reads the size and then loads the viewer
        //   this.isViewerLarge = true;
        // } else{
        //   this.isViewerLarge = false;
        // }

        this.isViewerLarge = !this.isViewerLarge;

        this.panoPlayer.setShowBboxTool(this.isViewerLarge);
        this.panoPlayer.setShowMeasureTool(this.isViewerLarge);
        this.panoPlayer.setShowViewMetadata(this.isViewerLarge);
        this.panoPlayer.setShowCompass(this.isViewerLarge);
        this.panoPlayer.setShowResolutionImage(this.isViewerLarge);
        this.panoPlayer.setShowSettings(this.isViewerLarge);
    }

    // Navigation
    _panoFrontAction() {
        const newPoint = GeometryHelper.createPoint(this.panoPlayer.getNextPosition(0));
        this.loadCaptureGeographic(newPoint, 15);

        if (environment.googleTracking) {
            gtag('event', 'panoFrontAction');
        }
    }

    _panoLeftAction() {
        const newPoint = GeometryHelper.createPoint(this.panoPlayer.getNextPosition(90));
        this.loadCaptureGeographic(newPoint, 15);

        if (environment.googleTracking) {
            gtag('event', 'panoLeftAction');
        }
    }

    _panoRightAction() {
        const newPoint = GeometryHelper.createPoint(this.panoPlayer.getNextPosition(-90));
        this.loadCaptureGeographic(newPoint, 15);

        if (environment.googleTracking) {
            gtag('event', 'panoRightAction');
        }
    }

    _panoBackAction() {
        const newPoint = GeometryHelper.createPoint(this.panoPlayer.getNextPosition(180));
        this.loadCaptureGeographic(newPoint, 15);

        if (environment.googleTracking) {
            gtag('event', 'panoBackAction');
        }
    }

    _linearNextAction() {
        this.loadCaptureLinear(1);

        if (environment.googleTracking) {
            gtag('event', 'linearNextAction');
        }
    }

    _linearPreviousAction() {
        this.loadCaptureLinear(-1);

        if (environment.googleTracking) {
            gtag('event', 'linearPreviousAction');
        }
    }

    _imageFormatTypeChangeAction() {

        this.displayCapture(this.panoPlayer.getImageFormatType());

        if (environment.googleTracking) {
            gtag('event', 'imageFormatTypeChangeAction');
        }
    }

    // Event emit
    static _sendLoadView(event) {
        window.parent.postMessage(
            {
                event: 'loadView',
                details: event.metadata
            },
            '*'
        );

        if (environment.googleTracking) {
            gtag('event', 'loadView');
        }
    }

    static _sendCloseMe() {
        window.parent.postMessage(
            {
                event: 'closeMe'
            },
            '*'
        );

        if (environment.googleTracking) {
            gtag('event', 'closeViewer');
        }
    }

    static _sendMeasurement(event) {
        window.parent.postMessage(
            {
                event: 'measurement',
                mode: event.mode,
                value: event.value
            },
            '*'
        );

        if (environment.googleTracking) {
            gtag('event', 'measurement');
        }
    }

    _sendBboxCreated(event) {
        let eventComplete = {};
        eventComplete = event;
        let view = this.views.find(o => o.viewStorageFilePath.includes('PANO_360_L'));
        eventComplete['detail'].viewIdentifier = view.viewIdentifier;
        eventComplete['detail'].view = view;


        window.parent.postMessage(
            {
                event: 'bboxCreated',
                mode: eventComplete['mode'],
                value: eventComplete['detail']
            },
            '*'
        );

        if (environment.googleTracking) {
            gtag('event', 'measurement');
        }
    }


    displayCapture(mode: string) {
        let view: ViewModel;

        for (let item of this.views) {
            if (item.viewType === mode) {
                view = item;
                break;
            }
        }

        if (view) {
            // View
            this.mode = view.viewType;

            this.location = this.capture.captureGeometry;

            this.imageUrl = this.storageUrl + '/' + view.viewStorageFilePath;

            this.panoPlayer.setImageUrl(this.imageUrl, this.mode, this.capture);
            console.log("CaptureHeight : " + this.capture.captureDetails["hardwareCaptureHeight"] || 2.50);
            this.panoPlayer.updateVehicleHeight(this.capture.captureDetails["hardwareCaptureHeight"] || 2.50);

            // Elements - bbox
            if (this.elements !== undefined) {
                for (let item of this.elements) {
                    if (this.mode == 'GROUND_L' && item.elementType == 'GROUND') {
                        this.panoPlayer.drawGroundBbox(item.viewBbox, item.elementName.toUpperCase(), this._getElementColor(item));
                    } else if (this.mode != 'GROUND_L' && item.elementType != 'GROUND') {
                        console.log(item.viewBbox);

                        let options = [];
                        options['color'] = this._getElementColor(item);
                        options['nameColor'] = this._getElementTextColor(item);
                        options['outlineWidth'] = 3;
                        options['nameFont'] = "150px Arial";

                        this.panoPlayer.drawPanoramaBbox(item.viewBbox, item.elementName.toUpperCase(), item, options);
                    }
                }
            }
        } else {
        }
    }

    _getElementColor(element: ElementModel) {
        let color: string = '#B80C09';

        console.log(element);

        if (element.elementProperties !== undefined && element.elementProperties["ui"] !== undefined
                && element.elementProperties["ui"]["bbox_color"] !== undefined) {
            color = element.elementProperties["ui"]["bbox_color"];
        }

        return color;
    }

    _getElementTextColor(element: ElementModel) {
        let color: string = '#B80C09';

        console.log(element);

        if (element.elementProperties !== undefined && element.elementProperties["ui"] !== undefined
            && element.elementProperties["ui"]["bbox_text_color"] !== undefined) {
            color = element.elementProperties["ui"]["bbox_text_color"];
        }

        return color;
    }

    loadCaptureGeographic(geometry: GeometryModel, distance: number) {
        let point: GeometryModel;

        if (GeometryHelper.isValidPoint(geometry))
            point = geometry;
        else {
            point = GeometryHelper.getCentroid(geometry)
        }

        let excludeViewIdentifier: string = (this.capture != undefined) ? this.capture.captureIdentifier : undefined;


        this.viewerService.findNearestCapturePoint(point, distance, excludeViewIdentifier, this.token).subscribe(
            (response: CapturePointResponseModel) => {
                console.log(response);
                if (response && response.capture && response.capture.captureIdentifier) {
                    this.capture = response.capture;
                    this.views = response.views;
                    this.elements = response.elements;
                    this.storageUrl = response.storageUrl;

                    this.displayCapture(this.mode);
                } else {
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'Capture not found',
                        detail: 'sorry, nothing to show'
                    });
                }
            }
        );
    }

    loadCaptureLinear(orderOffset) {
        if (this.capture == undefined || this.capture.snapshot == undefined) {
            return;
        }
        this.viewerService.getCapturePoint(undefined, undefined, this.capture.snapshot.snapshotIdentifier, this.capture.captureOrder + orderOffset, this.token).subscribe(
            (response: CapturePointResponseModel) => {
                if (response && response.capture && response.capture.captureIdentifier) {
                    this.capture = response.capture;
                    this.views = response.views;
                    this.elements = response.elements;
                    this.storageUrl = response.storageUrl;

                    this.displayCapture(this.mode);
                } else {
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'Capture not found',
                        detail: 'sorry, nothing to show'
                    });
                }
            }
        );
    }

    loadCaptureView(viewIdentifier) {
        this.viewerService.getCapturePoint(undefined, viewIdentifier, undefined, undefined, this.token).subscribe(
            (response: CapturePointResponseModel) => {
                if (response && response.capture && response.capture.captureIdentifier) {
                    this.capture = response.capture;
                    this.views = response.views;
                    this.elements = response.elements;
                    this.storageUrl = response.storageUrl;

                    this.displayCapture(this.mode);
                } else {
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'Capture not found',
                        detail: 'sorry, nothing to show'
                    });
                }
            }
        );
    }

}
