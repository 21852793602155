import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {AppService} from "./app.service";
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {ToastModule} from 'primeng/toast';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { HttpCredentialsRequestInterceptor } from './globals/interceptors/HttpCredentialsRequestInterceptor';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ToastModule,
    BrowserAnimationsModule,
    ProgressSpinnerModule
  ],
  providers: [
      AppService,
    {
      provide: HTTP_INTERCEPTORS, useClass: HttpCredentialsRequestInterceptor, multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
