import {GeometryModel} from './geometry-model';
import {ISerializable} from './iserializable';
import {FeaturePropertiesModel} from "./feature-properties-model";

export class FeatureModel implements ISerializable  {

  public type: string = 'Feature';

  public geometry: GeometryModel;

  public properties: FeaturePropertiesModel;

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input != null) {
      if (input.geometry) {
        this.geometry = new GeometryModel().deserialize(input.geometry);
      }
      if (input.properties) {
        this.properties = new FeaturePropertiesModel().deserialize(input.properties);
      }
    }
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
