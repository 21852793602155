const urlApi = 'https://api.citymagine.com';

export const environment = {
    auth : {
        apiUri: urlApi+'/*'
    },
    production: true,
    googleTracking: true,
    urlGeoJsonGetObjectElementCollection: urlApi + '/citymagine-api-1.0/rest/geojson/getObjectElementCollection',
    urlGeoJsonGetObjectViewCollection: urlApi + '/citymagine-api-1.0/rest/geojson/getObjectViewCollection',

    // ViewerService
    urlGetViewCollection: urlApi + '/citymagine-api-1.0/rest/geojson/getViewCollection',
    urlFindNearestViewCollection: urlApi + '/citymagine-api-1.0/rest/geojson/findNearestViewCollection',
    urlFindNearestCapturePoint: urlApi + '/citymagine-api-1.0/rest/viewer/findNearestCapturePoint',
    urlGetCapturePoint: urlApi + '/citymagine-api-1.0/rest/viewer/getCapturePoint',
};
