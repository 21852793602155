import {ISerializable} from './iserializable';


export class BboxModel implements ISerializable {

  public x: Number;
  public y: Number;
  public height: Number;
  public width: Number;
  public color: String;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
