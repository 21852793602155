import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';
import {HttpHeadersEnum} from './enums/http-headers-enum.enum';
import {ContentTypesEnum} from './enums/content-types-enum.enum';
import {AuthorizationTypesEnum} from './enums/authorization-types-enum.enum';
import {ResponseLevelEnum} from './enums/response-level-enum.enum';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {FeatureCollectionModel} from "./models/feature-collection-model";

@Injectable({
    providedIn: 'root'
})
export class AppService {

    constructor(private http: HttpClient) {
    }

    findNearestViewCollection(token, long, lat, mode,distance, excludeViewIdentifier = ''): Observable<FeatureCollectionModel>  {

        const url = environment.urlFindNearestViewCollection
            + '?viewType=' + mode + '&distance=' + distance + '&long=' + long + '&lat=' + lat + '&excludeViewIdentifier=' + excludeViewIdentifier;

        const headers = new HttpHeaders()
            .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
            .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BASIC + ' ' + token);

        const options = {headers};

        return this.http.get(url, options).pipe(map(data => new FeatureCollectionModel().deserialize(data)));
    }

    getViewCollection(token, snapshotIdentifier, viewOrder, viewType): Observable<FeatureCollectionModel>  {

        const headers = new HttpHeaders()
            .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
            .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BASIC + ' ' + token);

        const options = {headers};

        return this.http.post(environment.urlGetViewCollection, {
            header: {
                responseLevel: [
                    ResponseLevelEnum.ALL
                ]
            },
            viewType,
            snapshotIdentifier,
            viewOrder,
        }, options).pipe(map(data => new FeatureCollectionModel().deserialize(data)));
    }

    getViewIdentifier(token,viewIdentifier): Observable<FeatureCollectionModel>  {

        const headers = new HttpHeaders()
            .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
            .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BASIC + ' ' + token);

        const options = {headers};

        return this.http.post(environment.urlGeoJsonGetObjectViewCollection, {
            header: {
                responseLevel: [
                    ResponseLevelEnum.ALL
                ]
            },
            viewIdentifier
        }, options).pipe(map(data => new FeatureCollectionModel().deserialize(data)));
    }
}
