import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpHeadersEnum} from '../enums/http-headers-enum.enum';
import {ContentTypesEnum} from '../enums/content-types-enum.enum';
import {AuthorizationTypesEnum} from '../enums/authorization-types-enum.enum';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {FeatureCollectionModel} from '../models/feature-collection-model';
import {GeometryModel} from '../models/geometry-model';

import {CapturePointResponseModel} from "../models/capture-point-response-model";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ViewerService {

  constructor(  private httpService: HttpClient) {}

  errorMessage: any;
  @Output() ErrorMessage: EventEmitter<any> = new EventEmitter();

  displayStatus(errorMessage){
    this.errorMessage = errorMessage;
    this.ErrorMessage.emit(this.errorMessage);
  }

  findNearestCapturePoint(point: GeometryModel, distance:number, excludeCaptureIdentifier = '',token =''): Observable<CapturePointResponseModel> {
    let url = environment.urlFindNearestCapturePoint + '?long=' + point.coordinates[0] + '&lat=' + point.coordinates[1] + '&distance=' + distance + '&excludeCaptureIdentifier=' + excludeCaptureIdentifier;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + ' ' + token);

    const options = {headers};

    return this.httpService.get(url , options).pipe(map(data => new CapturePointResponseModel().deserialize(data)));
  }

  getCapturePoint(captureIdentifier, viewIdentifier, snapshotIdentifier, captureOrder,token =''):Observable<CapturePointResponseModel> {
    let url = environment.urlGetCapturePoint;

    if (captureIdentifier) {
      url = url + '?captureIdentifier=' + captureIdentifier;
    } else if (viewIdentifier) {
      url = url + '?viewIdentifier=' + viewIdentifier;
    } else {
      url = url + '?snapshotIdentifier=' + snapshotIdentifier + '&captureOrder=' + captureOrder;
    }

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + ' ' + token);

    const options = {headers};

    return this.httpService.get(url , options).pipe(map(data => new CapturePointResponseModel().deserialize(data)));
  }

  // @Deprecated
  findNearestViewCollection(point: GeometryModel, distance:number, viewType:string, excludeViewIdentifier = '',token =''): Observable<FeatureCollectionModel> {
    let url = environment.urlFindNearestViewCollection + '?viewType=' + viewType + '&long=' + point.coordinates[0] + '&lat=' + point.coordinates[1] + '&distance=' + distance + '&excludeViewIdentifier=' + excludeViewIdentifier;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + ' ' + token);

    const options = {headers};

    return this.httpService.get(url , options).pipe(map(data => new FeatureCollectionModel().deserialize(data)));
  }

  // @Deprecated
  getViewCollection(snapshotIdentifier, viewOrder, viewType,token =''): Observable<FeatureCollectionModel>  {
    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + ' ' + token);

    const options = {headers};

    return this.httpService.post(environment.urlGetViewCollection, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      viewType,
      snapshotIdentifier,
      viewOrder,
    }, options).pipe(map(data => new FeatureCollectionModel().deserialize(data)));
  }
}
