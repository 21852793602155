import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlHelper {

  constructor() {}

  static isNotEmpty(value: string): boolean {
    return (value !== undefined
          && value !== '' && value !== null)
  }

  static getUrlVars(url: string) {
      const vars = {};
      url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
          return vars[key] = value;
      });
      return vars;
  }

  // add values by default
  static getUrlParam(url: string, parameter, defaultvalue) {
      let urlparameter = defaultvalue;
      if (url.indexOf(parameter) > -1) {
          urlparameter = UrlHelper.getUrlVars(url)[parameter];
      }
      return urlparameter;
  }

  static getUrlParamTools(url: string, parameter, defaultvalue) {
      let urlparameter = defaultvalue;
      if (url.indexOf(parameter) > -1) {
          urlparameter = UrlHelper.getUrlVars(url)[parameter];
          // check if false to transform it to boolean
          if (urlparameter !== 'true') {
              urlparameter = false;
          }
      }
      return urlparameter;
  }
}
