import {ISerializable} from './iserializable';

export class ViewModel implements ISerializable {

  public viewIdentifier: string;
  public viewOrder: number;
  public viewType: string;
  public viewTimestamp: Date;
  public viewStorageType: string;
  public viewStorageFilePath: string;
  public viewStorageFileName: string;
  public viewProperties: object = {};

  deserialize(input: any): this {
    Object.assign(this, input);

    this.viewTimestamp = new Date(input.viewTimestamp);

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
